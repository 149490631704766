<template>
  <div class="m-ref-card" v-bind:class="{ 'm-ref-card--line': this.typeLine }">

    <div
      class="m-ref-card__container"
      v-bind:class="{ 'm-ref-card__container--active': isActive }"
    >
      <div class="m-ref-card__inner">
        <div class="m-ref-card__text--type">
          {{ this.tipoReferto }}
        </div>
        <div v-if=false class="m-ref-card__icon-container">
          <div v-if="this.tipoFile == 'PDF'">
            <span class="icon-adobe text-primary"></span>
          </div>
          <div class="m-ref-card__icon-container--bg" v-else>
            <div>
              <span class="icon-ecografia text-primary"></span>
            </div>
          </div>
        </div>
        <div class="m-ref-card__data">
          {{ this.dataFormatted }}
        </div>
        <div class="m-ref-card__text--name">
          {{ this.name }}
        </div>
        <div class="m-ref-card__text--name">
          {{ this.episodio }}
        </div>

      </div>

      <div class="m-ref-card__action" v-if="this.typeLine === false">
        <app-circle-icon
          v-if="this.link_eye"
          variant="medium"
          type="Hollow"
          icon="icon-eye"
          typeButton="true"
          :link="viewRef"
          class="m-ref-card__action--eye mr-1"
        ></app-circle-icon>
        <app-circle-icon
            v-if="this.fatts.length>0  "
            variant="medium"
            type="Hollow"
            icon="icon-fatture"
            typeButton="true"
            :link="viewFatt"
            class="m-ref-card__action--download mr-1"
        ></app-circle-icon>
        <app-circle-icon
          v-if="this.link_download !== ''"
          variant="medium"
          type="Hollow"
          :icon="(!published ? 'icon-refresh' : 'icon-download')"
          typeButton="true"
          :link="downloadRef"
          class="m-ref-card__action--download mr-1"
        ></app-circle-icon>
        <app-circle-icon
          v-if="this.link_share !== ''"
          variant="medium"
          type="Hollow"
          icon="icon-share"
          typeButton="true"
          :link="shareRef"
          class="m-ref-card__action--share mr-1"
        ></app-circle-icon>

      </div>

      <div class="m-ref-card__action" v-if="this.typeLine === true">
        <div class="m-ref-card__action--download">
          <button @click="downloadRef()" v-if="this.link_download !== ''">
            <span v-if="published" class="icon-download text-primary"></span>
            <span v-if="!published" class="icon-refresh text-primary"></span>
          </button>
        </div>
        <div v-if="showFatts" class="m-ref-card__action--download">
          <button
              v-if="this.fatts.length>0"
              @click="viewFatt()"
          >
            <span  class="icon-fatture text-primary"></span>

          </button>
        </div>

        <div class="m-ref-card__action--eye">
          <button
            v-if="this.link_eye"
            @click="viewRef()"
          >
            <span v-if="published" class="icon-eye text-primary"></span>
            <span v-if="!published" class="icon-lock text-primary"></span>
          </button>
        </div>


        <div class="m-ref-card__action--share">
          <button v-if="this.link_share !== ''" @click="shareRef()">
            <span class="icon-share text-primary"></span>
          </button>
        </div>

      </div>

    </div>
    <div  v-if="this.typeLine === false" class="col-12"><hr></div>
  </div>
</template>

<script>
import AppCircleIcon from "./AppCircleIcon.vue";
//import router from "@/router";
export default {
  name: "AppRefCard",
  components: { AppCircleIcon },

  props: {
    link_download: {
      default: "",
    },
    link_share: {
      default: "",
    },
    link_eye: {
      default: "",
    },
    labelType: {
      default: "Laboratorio",
    },
    name: {
      default: "(none)",
    },

    typeLine: {
      default: false,
    },

    isActive: {
      default: false,
    },
    tipoFile:{
      default:"PDF",
    },
    episodio:{
      default:"",
    },

    data_referto: {
      default: "20/06/2020",
    },
    info64:{
      default:null
    },
    published:{
      default:false
    },
    fatts:{
      default:[]
    },
    showFatts:{
      default:false
    }
  },
  data() {
    return {
      card: this.card,
    };
  },
  computed: {

    dataFormatted: function()
    {
      let ff=this.data_referto.substr(0,10);
          return ff;
    },
      tipoReferto:function () {
        switch(this.labelType)
        {
          case "CDIMed-NSI":
            return "MDL";
          case "METAFORA":
            return "Anatomia Patologica";
          case "ESAOTE":
            return "Imaging";
          case "MODULAB":
          case "HDOCS-LAB":
            return "Laboratorio";
          case "HDOCS-AMB":
            return "Poliambulatorio";
          default:
            return "Referto";
        }

    }
  },
  mounted() {},
  methods: {
    changeElement() {
      this.card = !this.card;
    },
    viewRef() {
      this.$emit("viewRefert",{});
    },
    shareRef() {
      //router.push("/refertiShare?id="+this.info64);
      this.$emit("shareRefert",{});
    },
    downloadRef() {
      this.$emit("openRefert",{});
    },
    viewFatt(){
      this.$emit("viewFatt",{});
    }


  },
};
</script>
