<template>
  <div class="m-breadcrumb" >
    <div class="m-breadcrumb__container">
      <div class="m-breadcrumb__line">
        <div v-for="item in this.itemList"  v-bind:key="item.label">

        <div v-if="item.link==null" class="m-breadcrumb__item">
          <span :class="'mr-2 icon-'+item.icon" style="font-size: large;" v-if="item.icon!=null"></span>
          {{ $t(item.label) }}
          <label class="separator" v-if="item.separator"> / </label>
        </div>
        <div v-if="item.link!=null" class="m-breadcrumb__link">
          <span :class="'mr-2 icon-'+item.icon" style="font-size: large;" v-if="item.icon!=null"></span>
          <router-link :to="item.link" style="text-decoration: underline">{{$t(item.label)}}</router-link>
          <label class="separator" v-if="item.separator"> / </label>
        </div>

      </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AppBreadcrumb",
  props: {
    items: {
      default: "",
    },
  },
  data() {
    return {};
  },
  computed:{
    itemList(){
      const toks=this.items.split("|");
      let data=[];
      toks.forEach(function(itm){
        const pro=itm.split(";");
        let tmp= {
          label:'',
          link:null,
          icon:null,
          separator:true
        };
      //  console.log(pro);
        if (pro.length==0)
          return;
        tmp.label=pro[0];
        if (pro.length>1)
        {
          tmp.link=pro[1];
        }
        if (pro.length>2)
        {
          tmp.icon=pro[2];
        }
        data.push(tmp)
      })
      if (data.length>0)
      {
        data[data.length-1].separator=false;
      }
     // console.log(data);
      return data;
    }
  },

  methods: {},
};
</script>
