<template>
  <div class="registrazione">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <app-ref-head @change-type="updateRow" :jit="jit" :patient="options.length>0?options[0].text:''" :service="service"></app-ref-head>
        <app-breadcrumb v-if="!jit && service==='mdl'" items="mdl.bread_home;/mdl;home"> </app-breadcrumb>
        <div class="row no-gutters mt-3 mb-0">
          <div class="col-12 offset-0">
            <b-alert  variant="warning" id="errore_waiting" v-model="isMdl" ><strong>{{$t('mdl.consensi.referti.p0')}}</strong><br>
              <strong>{{$t('mdl.consensi.referti.p1')}}</strong> {{$t('mdl.consensi.referti.p2')}}<router-link to="/dettagliUtenza?tab=6" ><strong class="alert-warning"><u>{{$t('mdl.consensi.referti.l0')}}</u></strong></router-link>
            </b-alert>

          </div>
        </div>
        <div class="row no-gutters mt-3">
          <div
            class="col-12 offset-0"
            v-bind:class="{
              'col-lg-10 offset-lg-1 col-xl-10 offset-xl-1': !this.row,
            }"
          >

            <div class="o-ref-list__user" v-if="false">
                <div class="o-ref-list__select">
                  <span class="o-ref-list__select--icon icon-user2"></span>
                  <b-input-group prepend="I REFERTI DI">
                    <b-form-select
                      v-model="selected"
                      :options="options"
                    ></b-form-select>
                  </b-input-group>
                  <span
                    class="o-ref-list__select--action icon-arrow-bottom"
                  ></span>
                </div>
                <div
                  class="o-ref-list__info text-light"
                  v-if="false"
                >
                  <span class="o-ref-list__info--icon icon-user2"></span>
                  <span class="o-ref-list__info--name"
                    >I REFERTI DI Giacomo Magnani</span
                  >
                </div>
            </div>
            <app-list-pager v-if="!jit" :loading="this.loading" :pages="this.pages" :count="this.total" :rpp="this.rpp" :page="this.page" @onPageChange="changePage($event)"></app-list-pager>

            <app-ref-list class="d-none d-lg-block" :selectTypeLine="row" :referti="referti" :selected="selected" :jit="jit" :service_mode="isMdl?'mdl':''"></app-ref-list>
            <app-ref-list class="d-block d-lg-none" :selectTypeLine="line" :referti="referti" :selected="selected" :jit="jit" :service_mode="isMdl?'mdl':''"></app-ref-list>

            <app-list-pager v-if="!jit" :loading="this.loading" :pages="this.pages" :count="this.total" :rpp="this.rpp" :page="this.page" @onPageChange="changePage($event)"></app-list-pager>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppRefHead from "../components/organisms/AppRefHead.vue";
import AppRefList from "../components/organisms/AppRefList.vue";
import apiAxios from "@/libs/apiAxios";
import AppBreadcrumb from "@/components/molecules/AppBreadcrumb";
import AppListPager from "@/components/molecules/AppListPager";
export default {
  components: {AppBreadcrumb,AppListPager, AppRefHead, AppRefList },
  name: "referti",

  created() {
    this.getPageFromApi(0);
  },
  data() {
    return {
      loading:true,
      pages:0,
      page:0,
      total:0,
      rpp:1,
      jit:false,
      referti:[],
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },

      row: true,

      selected: "a",
      options: [
  /*      { value: "a", text: "Giacomo Magnani" },
        { value: "b", text: "Mario Rossi" },*/
      ],
    };
  },

  computed:{
    service:function (){
      let msp=this.$route.query.mdl;
      if (msp!=null) {
        if (msp === 'true' || msp==="1") {
          return 'mdl';
        }
      }
      return 'referti';
    },
    isMdl:function(){
      return this.service==='mdl';
    }
  ,
    realMSP:function (){
      let msp=this.$route.query.mdl;
      if (msp!=null) {
        if (msp === 'true') {
          return 'mdl';
        }
      }
      return null;
    }
  },

  methods: {

    updateRow(e) {
      this.row = e;
    },
    loadData(data)
    {

      this.data =data;
      this.rpp=data.pageSize;
      this.referti=data.referti;
      this.total=data.total;
      this.pages=data.pages;
      this.loading = false;
      this.page=data.page;

      this.options = [];
      if (data.referti.length>0)
      {
        this.options=[{text:data.referti[0].CognomeNome}]
      }
      /*     let peoples = {count:0};
           let codici = ;

     /*
           data.map(function (value) {
             const codiceMSP = "_" + value.CodiceMSP;

             if (!(codiceMSP in peoples)) {
               const people = {
                 codiceMSP: value.CodiceMSP,
                 fullName: value.CognomeNome,
                 dataNascita: value.DataNascita,
                 referti: []
               };
               peoples[codiceMSP] = people;
               codici.push(value.CodiceMSP)
             }

             peoples[codiceMSP].referti.push(value);
             peoples.count++;

           });

           this.referti = peoples;
           let selected = null;
           let options = [];
           codici.map(function (value) {
             if (selected == null) {
               selected = "_" + value;
             }
             options.push({
               value: "_" + value,
               text: peoples["_" + value].fullName
             });

           });
     */

      this.selected = null;

    },
    getDataFromApi() {
      this.getPageFromApi(null);
    },
    getPageFromApi(page){
      this.loading = true


      if (sessionStorage.getItem('referti')!=null)
      {
        this.loadData(JSON.parse(sessionStorage.getItem('referti')));
        this.jit=true;
      }
      else {
        const url='/'+(this.service=='mdl'?(page==null?"mdl/referti/":"mdl/referti/paged/"+page):(page==null?"referti/":"referti/paged/"+page));
        apiAxios.get(url)
            .then(response => {
              const data=this.service=='mdl'?response.data.ElencoRefertiPagedResult:response.data.ElencoRefertiPagedResult;
              this.loadData(data)
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
      }
    },
    changePage(p){
      this.getPageFromApi(p.page);
    }
  }

};
 /*   ,
    updateRow(e) {
      this.row = e;
    },*/


</script>
