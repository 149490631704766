<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class=" col-12 offset-0">
          <div class="m-card text-center">
            <div class="row no-gutters">
              <div class="col-lg-8 offset-lg-2">
                <h1 class="o-heading o-heading--xl">
                  {{ $t(service==="mdl"?"mdl.referti.title":"referti.title") }} <span v-if="jit"> di {{ patient }}</span>
                </h1>
                <p v-if="!jit">{{ $t(service==="mdl"?"mdl.referti.subtitle":"referti.subtitle") }}</p>
              </div>
              <div class="col-lg-2">
                <div
                  class="d-flex justify-content-end align-items-center h-100"
                >
                  <app-circle-icon
                    variant="Small"
                    :type="checkTypeLine"
                    icon="icon-view-column"
                    class="m-2"
                    typeButton="true"
                    :link="changeTypeCardLine"
                  ></app-circle-icon>
                  <app-circle-icon
                    variant="Small"
                    :type="checkTypeRow"
                    icon="icon-view-row"
                    class="m-2"
                    typeButton="true"
                    :link="changeTypeCardRow"
                  ></app-circle-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCircleIcon from "../molecules/AppCircleIcon.vue";
export default {
  components: { AppCircleIcon },
  name: "AppRefHead",
  emits: ["change-type"],
  props: ["userData", "jit", "patient","service"],

  data() {
    return {
      tabline: "row",
      line: true,
      row: true,
    };
  },
  computed: {
    checkTypeLine() {
      if (this.tabline === "line") {
        return "background";
      } else {
        return "Hollow";
      }
    },
    checkTypeRow() {
      if (this.tabline === "row") {
        return "background";
      } else {
        return "Hollow";
      }
    },
  },

  methods: {
    changeTypeCardLine() {
      this.tabline = "line";
      this.$emit("change-type", false);
    },
    changeTypeCardRow() {
      this.tabline = "row";
      this.$emit("change-type", true);
    },
  },
};
</script>
