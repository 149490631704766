<template>
  <div class=" row col-12 mt-1" v-if="!loading  && this.count>=1">

    <div   class="col-sm-12 col-md-4"><p>Referti {{this.rpp*this.page+1}} - {{(this.rpp*this.page+this.rpp)>this.count?this.count:(this.rpp*this.page+this.rpp)}} di {{this.count}}</p>
    </div>

    <div  class="col-sm-12 col-md-8 text-right">
      <b-button :variant="!btn.selected?'outline-primary':'primary'"  v-for="btn in this.getPageButtonData()" v-bind:key="btn.id"  class="btn-sm text-uppercase ml-1 mr-1"

                @click="doChangePage(btn.id)">{{btn.lbl}}</b-button>
    </div>

  </div>
</template>
<style>

</style>
<script>

//import router from "@/router";
export default {
  name: "AppListPager",
  components: {  },

  props: {
    page: {
      default: 0
    },
    rpp:{
      default:1
    },
    pages: {
      default: 0
    },
    count: {
      default: 0
    },
    loading:{
      default:false
    },
    midPageCount:{
      default:2
    }
  },
  data() {
    return {
      card: this.card,
    };
  },
  computed: {

  },
  mounted() {},
  methods: {

    getPageButtonData()
    {
      let res=[];
      if (this.page>1) {
        const btnFirst = {
          id: 0,
          lbl:"<<",
          selected: false,
        };
        res.push(btnFirst);
      }
      if (this.page>0) {
        const btnPrev = {
          id: this.page-1,
          lbl:"<",
          selected: false,
        };
        res.push(btnPrev);
      }


      const btn = {
        id: this.page,
        lbl: (this.page + 1),
        selected: true,
      };
      res.push(btn);

      if (this.page<(this.pages-1)) {
        const btnNext = {
          id: this.page+1,
          lbl: ">",
          selected: false,
        };
        res.push(btnNext);
      }

      if (this.page<(this.pages-2)) {
        const btnLast = {
          id: this.pages - 1,
          lbl: ">>",
          selected: false,
        };
        res.push(btnLast);
      }
      return res;
    },
    doChangePage(p)
    {
      if (p===this.page)
        return;
      this.$emit("onPageChange",{page:p});
    }

  },
};
</script>
