<template>
  <div
    class="o-ref-list mt-3"
    v-bind:class="{
      'o-ref-list--table': this.selectTypeLine,
    }"
  >
    <div v-if="!checkSanAmm() && !isLoading" class="m-card text-center pb-3">
      {{ $t("referti.no_check_amm_san") }}
    </div>

    <div v-if="checkSanAmm() && this.getReferti().length==0 && !isLoading" class="m-card text-center pb-3">
      {{ $t("referti.no_ref") }}
    </div>

    <div v-if="jit && !isDoc" class="alert alert-warning">
      <p><strong>{{$t("referti.singolo_45_avviso_title")}}</strong></p>
      <p>{{ $t("referti.singolo_45_avviso")}}</p>
    </div>

    <!--
    <b-alert v-if="actReferti.length>0 && checkSanAmm()" show variant="warning" class="text-center mb-5 col-10 offset-1 p-5" dismissible>
      <p>Se non risulta possibile visualizzare il referto o la fattura scaricata assicurarsi di consentire al browser sempre l'uso dei popup cliccando sulla seguente icona nella barra dell'indirizzo:</p>
      <b-img fluid center src="../../assets/blocco-popup.png" alt="Blocco popup"></b-img>
    </b-alert>
    -->

    <div class="o-ref-list__head" v-if="selectTypeLine === true && getReferti().length>0 && checkSanAmm()">
      <div class="o-ref-list__inner">
        <div class="o-ref-list--data">
          {{ $t("referti.tab_0") }}
        </div>
        <div class="o-ref-list--type">
          {{ $t("referti.tab_1") }}
        </div>
        <div class="o-ref-list--name">
          {{ $t("referti.tab_2") }}
        </div>
        <div class="o-ref-list--name">
          {{ $t("referti.tab_2A") }}
        </div>
      </div>

      <div class="o-ref-list__action">
        <div class="o-ref-list--download">
          {{ $t("referti.tab_3") }}
        </div>
        <div v-if="jit" class="o-ref-list--img">
          {{ $t("referti.tab_3_1") }}
        </div>
        <div  class="o-ref-list--img">
          {{ $t("referti.tab_4") }}
        </div>
        <div class="o-ref-list--send">
          {{ $t("referti.tab_5") }}
        </div>
      </div>
    </div>

    <app-ref-card v-bind:key="refer.info" v-for="refer in (checkSanAmm() ? this.getReferti(): [])"
      link_download="Large"
      :link_share="(isDoc)?'':'Large'"
      :link_eye="refer.Sorgente==='ESAOTE'"
      :labelType="refer.Sorgente"
      :name="refer.Prestazione"
      :typeLine="selectTypeLine"
      :isActive="refer.Publish"
                  :episodio="refer.Episodio"
      :tipo-file="refer.Sorgente==='ESAOTE'?'IMG':refer.TipoFile"
      :data_referto="refer.DataPrelievo"
      :info64="refer.info"
      :published="refer.Publish"
                  :fatts="(('fatts' in refer)?refer.fatts:[])"
                  :show-fatts="jit & !isDoc"
      @openRefert="pdfOnNewWindow(refer.info,refer.Publish,false,refer)"
      @viewRefert="estensaOnNewWindow(refer.info,refer.Publish,false,refer)"
      @shareRefert="shareRefert(refer.info,refer.Publish,false,refer)"
      @viewFatt="viewFatt(refer)"

    ></app-ref-card>

    <b-modal ref="share_dialog"
      id="download_referti"
      centered
      modal-class="o-modal"
      content-class="o-modal__content"
      header-class="o-modal__header"
      dialog-class="o-modal__dialog"
      body-class="o-modal__body o-modal__body--response "
      ok-title="ok-title"
      :cancel-disabled="true"
      :hide-footer="true"
      :hide-header-close="true"
      :hide-header="true"
      size="lg"
    >
      <div class="o-modal__body--icon">
        <span class="icon-email"></span>
        <span v-if="this.shareError" class="icon-close text-light"></span>

      </div>
      <p class="o-modal__body--title text-success">
        <strong>Invia referto </strong>
      </p>
      <p>Specifica telefono ed email del medico destinatario</p>

      <b-form-group
          id="tel"
          label-for="tel-input"
          class="m-input m-input__tel mt-3"
      >
          <span class="d-flex w-100">
            <div class="icon-phone-check mr-3"></div>
            <b-form-input
                id="tel-input"
                v-model="docTel"
                type="tel"
                :state="isPhone(docTel)"
                :placeholder="this.$t('registration.tel')"
                required
            ></b-form-input>
          </span>

        <b-form-invalid-feedback class="">
          {{ $t("step.invalid_tel") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
          id="email"
          label-for="email-input"
          class="m-input m-input__icon"
      >
        <div class="icon-email mr-3"></div>
        <b-form-input
            id="email-input"
            v-model="docMail"
            type="email"
            :state="isEmail(docMail)"
            :placeholder="this.$t('registration.email')"
            aria-describedby="input-feedback-email"
            required
        ></b-form-input>
      </b-form-group>

      <p v-if="shareError">
        <strong >{{ $t("referti.errors."+this.sharedMessage) }}</strong>
      </p>
      <b-button variant="primary" size="md" class="text-uppercase mt-3" @click="inviaReferto()"
        >INVIA </b-button>
      <b-button variant="outline-primary" size="md" class="text-uppercase mt-3" @click="closeInviaReferto()"
      >ANNULLA </b-button>
    </b-modal>

    <b-modal ref="error-view"
             id="error-view"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-eye"></span>
        <span  class="icon-close text-light"></span>
      </div>
      <p class="o-modal__body--title text-danger">

        <strong  >Problema con le Immagini </strong>

      </p>
      <p>

        <strong >Sono stati riscontrati problema con l'immagine</strong>
      </p>
      <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="ModalViewErrort_close()"
      >OK</b-button
      >
    </b-modal>

    <b-modal ref="check-fail"
             id="check-fail"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-eye"></span>
        <span  class="icon-close text-light"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong v-if="sanitario" >Vincolo Sanitario</strong>
        <strong v-if="!sanitario"  >Problema Amministrativo </strong>

      </p>
      <p>
        <strong v-if="sanitario">Non è possibile visualizzare / scaricare / stampare referti di esami speciali (es HIV).Il referto deve essere ritirato in sede</strong> <br>


        <strong v-if="!sanitario">Non è possibili visualizzare / scaricare / stampare i referti in caso sussistano problemi amministrativi. Si prega di contattare la struttura.</strong>
      </p>
      <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="closeMessaggio()"
      >OK</b-button
      >
    </b-modal>

    <b-modal ref="query_result"
      id="query-result"
      centered
      modal-class="o-modal"
      content-class="o-modal__content"
      header-class="o-modal__header"
      dialog-class="o-modal__dialog"
      body-class="o-modal__body o-modal__body--response "
      ok-title="ok-title"
      :cancel-disabled="true"
      :hide-footer="true"
      :hide-header-close="true"
      :hide-header="true"
      size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-eye"></span>
        <span v-if="!this.querySucces" class="icon-close text-light"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong v-if="this.querySucces"> Richiesta accettata </strong>
        <strong v-if="!this.querySucces"> Richiesta non accettata </strong>
      </p>
      <p>
        <strong v-if="this.querySucces">
         La richiesta dei ripristino referto è stata accettata. Riprovare domani!</strong
        >

        <strong v-if="!this.querySucces">
          La richiesta dei ripristino referto non è stata accettata.</strong
        >
      </p>
        <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="closeQueryResult()"
        >OK</b-button
      >
    </b-modal>

    <b-modal ref="referto_error"
             id="referto_error"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-eye"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong> Attenzione </strong>
      </p>
      <p>
        {{$t(this.errorMessage)}}
      </p>
      <!--<b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryReferto"
      >RICHIEDI PUBBLICAZIONE</b-button
      >-->
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="downloadError(false)"
      >OK</b-button
      >

    </b-modal>

    <b-modal ref="referto_paga"
             id="referto_paga"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-refresh"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Richiesto Pagamento </strong>
      </p>
      <p>
        <strong>Non è possibili visualizzare / scaricare / stampare i referti in caso sussistano problemi con pagamenti insoluti. Si prega di accedere alla sezione fatture, o se non ancora abilitati di contattare la struttura.</strong>
      </p>
      <!--<b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryReferto"
      >RICHIEDI PUBBLICAZIONE</b-button
      >-->
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="ModalViewPaga_close"
      >OK</b-button
      >

    </b-modal>

    <b-modal ref="referto_notready"
             id="referto-notready"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-refresh"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Referti immagini in fase di ripubblicazione </strong>
      </p>
      <p>
        <strong>La procedura di ripubblicazione può richiedere fino a 24 ore. Si consiglia di accedere nei prossimi giorni.</strong><br>
        Se dopo 24 ore le immagini non sono ancora visibili si prega di <strong>verificare quali immagini siano visibili sull’informativa</strong>.

      </p>
      <!--<b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryReferto"
      >RICHIEDI PUBBLICAZIONE</b-button
      >-->
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="closeNotReady"
      >OK</b-button
      >

    </b-modal>

    <b-modal ref="referto_fatture"
             id="referto-fatture"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-fatture o-heading--xl"></span>
        <span class="o-heading--xl">Fatture</span>
      </div>


      <div class="container">
      <div class=" d-none d-lg-flex border-bottom pb-2 mb-2" >

          <div class="col-3">
            <strong>Data</strong>
          </div>
          <div class="col-6">
            <strong>Numero Documento</strong>
          </div>


          <div class="col-3">
            <strong>Azioni</strong>
          </div>


      </div>

      <div class=" d-block d-lg-flex col-12 mb-2 align-middle border-bottom" v-bind:key="fatt.info" v-for="fatt in fattureModale">

          <div class="col-12 col-lg-3 pt-3">
            {{ fatt.DataDocumento==null?"":fatt.DataDocumento.substr(0,10) }}
          </div>
          <div class="col-12 col-lg-6 pt-3 text-lg-left text-center">
            {{ fatt.info.NumeroDocumento }}
          </div>
          <div class="col-12 col-lg-3 mt-2 mt-md-0 text-center text-lg-right mb-2">
            <button  class="btn btn-primary text-uppercase"

                @click="viewFatt2(fatt)"
            >
             Scarica

            </button>
          </div>
       </div>
      </div>

      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="ModalViewFatture_close"
      >ANNULLA</b-button
      >

    </b-modal>

    <b-modal ref="referto_scaduto"
      id="referto-scaduto"
      centered
      modal-class="o-modal"
      content-class="o-modal__content"
      header-class="o-modal__header"
      dialog-class="o-modal__dialog"
      body-class="o-modal__body o-modal__body--response "
      ok-title="ok-title"
      :cancel-disabled="false"
      :hide-footer="true"
      :hide-header-close="true"
      :hide-header="true"
      size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-refresh"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Referto da ripubblicare </strong>
      </p>
      <p>
        <strong>Il referto risulta pubblicato da oltre 45 giorni. Per procedere alla ripubblicazione, clicca sul pulsante RICHIEDI REFERTO, in caso di referti immagine l'operazione può richiedere fino a 24 ore.</strong>

      </p>
      <b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryReferto"
        >RICHIEDI REFERTO</b-button
      >
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="closeScaduto"
      >ANNULLA</b-button
      >

    </b-modal>
  </div>
</template>

<script>
import AppRefCard from "../molecules/AppRefCard.vue";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
import store from '@/store'

export default {
  components: { AppRefCard },
  name: "AppRefList",
  props: ["userData", "selectTypeLine","referti","selected","jit","service_mode"],

  data() {
    return {

      referto:null,
      action:null,
      querySucces:true,
      queryError:"",
      multiple: true,
      docMail:"",
      docTel:"",
      shareDoc:null,
      shareError:null,
      sharedMessage:null,
      sanitario:false,
      needRefresh:false,
      errorMessage:null,
      isDoc:false,
      fattureModale:[]


    };
  },

  created() {
    this.isDoc=this.isDottore();
    //console.log(this.isDoc);
  },
  computed: {
    actReferti: function()
    {
      /*if (this.selected==null)
        return [];*/
      if (this.referti==null)
        return [];
      if (!(this.selected in this.referti ))
          return []          
      return this.referti;//[this.selected].referti;
    },
    urlPath()
    {
      switch(this.service_mode)
      {
        case "mdl":
          return "mdl/referti";
      }
      return "referti";

    },
    isLoading()
    {
      return store.getters.isLoading;
    },    
  },

  methods: {


    getReferti()
    {
      if(this.referti==null)
        return [];
      return this.referti;
    },
    controlli(refer)
    {
      this.refertoObj=refer;

      if (refer!=null)
      {
        if ('ControlloAmministrativoOK' in refer) {
          if (!(refer.ControlloAmministrativoOK && refer.ControlloSanitarioOK && !refer.Speciale)) {
            if (!refer.ControlloSanitarioOK || refer.Speciale) {
              this.sanitario = true;
            }
            else {
              this.sanitario= false;
            }
            return false;
          }
          return true;
        }
      }
      return false;
    },
    ModalViewPublish($id,action)
    {
      console.log($id);

      this.referto=$id;
      this.action=action;
      this.$refs['referto_scaduto'].show();
    },

    ModalViewErrort() {

      this.$refs['error-view'].show();
    },

    ModalViewErrort_close() {

      this.$refs['error-view'].hide();
    },

    ModalViewRefert($id,action) {
      console.log($id);

      this.referto=$id;
      this.action=action;
      this.$refs['referto_scaduto'].show();
    },
    ModalViewFatture($fatts)
    {
      this.fattureModale=$fatts;
      this.$refs['referto_fatture'].show();
    },

    ModalViewFatture_close()
    {
      this.$refs['referto_fatture'].hide();
      this.fattureModale=[];
    },

    ModalViewPaga()
    {
      this.$refs['referto_paga'].show();
    },

    ModalViewPaga_close()
    {
      this.$refs['referto_paga'].hide();
    },
    closeReferto() {

      this.referto=null;
      this.$refs['referto_scaduto'].hide();
      this.$refs['referto-notready'].hide();
    },
    closeScaduto() {
      this.referto=null;
      this.$refs['referto_scaduto'].hide();
      this.$refs['referto-notready'].hide();
    },
    closeQueryResult() {

      this.querySucces=null;
      this.queryError=null;
      this.$refs['query_result'].hide();
    },

    queryReferto() {
      if (this.referto!=null)
      {
        const idi=this.referto;
        this.referto=null;
        this.sendQueryReferto(idi );
      }

     this.closeReferto();
     router.push("/referti");
    },

    closeNotReady()
    {
      this.$bvModal.hide('referto-notready');
      if (this.needRefresh)
        router.go();
    },


    ModalViewNotReady($id)
    {
      console.log($id);
      this.referto=$id;
      this.action='estensa'
      this.$bvModal.show('referto-notready');
    },

    sendQueryReferto($id)
    {


      apiAxios(this.urlPath+"/"+encodeURI($id)+'/query')
          .then(response => {
            if (response.data == -31) {
              console.log("No data for file");
              return;
            }

            const action=this.action;
            this.action=null;
            const imag= response.data.QueryRefertoResult.data;
            if (action==="pdf") {
              this.pdfOnNewWindow($id, true,true,this.refertoObj);

            }
            if (action=="estensa")
            {
              this.estensaOnNewWindow($id,true,true,this.refertoObj);
              console.log(imag);
            /*  this.querySucces=imag.success;
              this.queryError=imag.error;
              this.$refs["query_result"].show();*/

            }
            if (action=="share")
            {
              this.shareRefert($id,true,true,this.refertoObj);

            }


          });
    },
    shareRefert($id,publish,refresh,refer)
    {
      console.log("share referto");
      this.needRefresh=refresh;
      let cont=this.controlli(refer);
      console.log(cont);
      if (!cont)
      {
        this.showMessaggio();
        return;
      }
      if (!publish)
      {
        this.ModalViewPublish($id,'share');
        return;
      }
      const data={};
      apiAxios.post("/jit/fatture/referto/"+$id,data)
          .then(result => {
            const data=result.data.FattureRefertoResult;
            if (data.length==0)
            {
              router.push("/refertiShare?id="+$id+(this.service_mode==="mdl"?"&mdl=1":""));
            }
            else {
              const dFatt = {
                selectable: false,
                source: 'referti',
                list: btoa(JSON.stringify(data))
              }
              sessionStorage.setItem("invoices", JSON.stringify(dFatt));
              router.push("fattureDaPagare");
              return;
            }
          })
          .catch(error => {console.log(error);});



    },
    closeInviaReferto()
    {
      this.shareDoc=null;

      this.$refs['share_dialog'].hide();
    },
    inviaReferto()
    {
      if (this.shareDoc!=null)
      {
        const data={
          telefono:this.docTel,
          email:this.docMail
        }
        apiAxios.post(this.urlPath+"/"+encodeURI(this.shareDoc)+'/share',data)
        .then(response=>{
          const resp=response.data.ShareRefertoResult;
          if (resp.success)
          {
            this.closeInviaReferto();
          }
          else {
            this.shareError=true;
            this.sharedMessages=resp.messages;
          }
        })
      }
    },
    estensaOnNewWindow($id,publish,refresh,refer)
    {
      this.needRefresh=refresh;
      if (!this.controlli(refer))
    {
      this.showMessaggio();
      return;
    }
      if (!publish)
      {
        this.ModalViewPublish($id,'estensa');
        return;
      }
      //const _this=this;
      apiAxios("jit/"+this.urlPath+"/"+encodeURI($id)+'/view')
          .then(response => {
            if (response.data == -31) {
              console.log("No data for file");
              return;
            }
            if (response.data.ViewRefertoResult.pagam != null) {
             // if (!_this.isSingoloReferto()) {
                const dFatt = {
                  selectable: false,
                  source: 'referti',
                  list: response.data.DownloadRefertoResult.pagam
                }
                sessionStorage.setItem("invoices", JSON.stringify(dFatt));
                router.push("fattureDaPagare");
                return;
              //   }
             /* else {
                this.ModalViewPaga();
                return;
              }*/
            }
            const imag= response.data.ViewRefertoResult.data;
            if (imag.imaging=="READY")
            {
              //window.open(imag.urlImaging);
              location.href = imag.urlImaging;
              
              if (refresh)
                router.go();
              return;
            }
            if (imag.imaging=="NOT_READY")
            {
              this.ModalViewNotReady($id);
              return;
            }
            if (imag.imaging=="NO_IMAGE")
            {
              this.ModalViewError($id);
              return;
            }
          });

    },
    isPhone(phone) {
      // const regExp = /^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/g;
      const regExp = /^(\+|\d)[0-9]{7,16}$/g;
      if (phone != null && phone != "") {
        if (regExp.test(phone) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    isEmail(email) {
      const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
      if (email != null) {
        if (regExp.test(email) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    isDottore()
    {
      if (!this.jit) return false;
      const val=sessionStorage.getItem('singolo');
      if (val==null)
        return false;
      let bool=JSON.parse(val);
      return !bool ;
    },
    singoloReferto()
    {
      return this.jit;
      /*let res= sessionStorage.getItem('singolo')!=null;
      if (!res)
      {
        let ud= sessionStorage.getItem("userdata");
        if (ud==null)
        {
          return true;
        }
        return false;
      }*/
    },
    showMessaggio()
    {
      this.$refs['check-fail'].show();
    },
    closeMessaggio()
    {
      this.$refs['check-fail'].hide();
    },
    viewFatt2(fatt)
    {
      this.pdfFattura(fatt.info);
    },
    viewFatt(refer) {
      if (!("fatts" in refer))
        return;
      if (refer.fatts.length==0)
        return;
       if (refer.fatts.length==1)
      {
        this.pdfFattura(refer.fatts[0].info);
        return
      }
      this.ModalViewFatture(refer.fatts);
    },

    pdfFattura($info){
      const $id=btoa(JSON.stringify($info));
      let n_doc=$info.NumeroDocumento;

      apiAxios("jit/fatture/" + encodeURI($id) + '/download')
          .then(response => {
            if (response.data == -31) {
              console.log("No data for file");
              return;
            }

            const data =response.data.DownloadFatturaResult;
            if (!data.success)
            {
              this.noFatt = true;
              //alert("Errore:"+data.error)
              return;
            }
            const sliceSize = 512;
            const byteCharacters = atob(data.data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }


            const file = new Blob(
                byteArrays,
                {type: 'application/pdf'});
            //Build a URL from the file
            //const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            //window.open(fileURL);
            //location.href = fileURL;
            this.downloadFile(file, "Fattura_"+n_doc+".pdf");
          });


    },

    pdfOnNewWindow($id, publish,refresh,refer)
    {
      console.log(refer);
      if (!this.controlli(refer))
      {
        this.showMessaggio();
        return;
      }
      if (!publish)
      {
        this.ModalViewPublish($id,'pdf');
        return;
      }


      const _this=this;
      apiAxios("jit/"+this.urlPath+"/"+encodeURI($id)+'/download')
          .then(response => {
            if (response.data==-31)
            {
              console.log("No data for file");
              return;
            }

            if (!response.data.DownloadRefertoResult.published
                && response.data.DownloadRefertoResult.data==null)
            {

              this.ModalViewNotReady($id);
              return
            }


            if (response.data.DownloadRefertoResult.pagam!=null)
            {
         //     if (true!_this.singoloReferto()) {
                const dFatt = {
                  selectable: false,
                  source: 'referti',
                  list: response.data.DownloadRefertoResult.pagam
                }
                sessionStorage.setItem("invoices", JSON.stringify(dFatt));
                router.push("fattureDaPagare");
                return;
         /*     }
              else {
                this.ModalViewPaga();
                return;
              }*/
            }

            const sliceSize=512;
            const byteCharacters = atob(response.data.DownloadRefertoResult.data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }


            const file = new Blob(
                byteArrays,
                {type: 'application/pdf'});
            //Build a URL from the file
            //const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            //window.open(fileURL);
            //location.href = fileURL;
            this.downloadFile(file, "Referto_"+refer.Episodio+".pdf");

            if(refresh) router.go();
          })
          .catch(error =>{
            console.log(error);
            let message="referti.error.ERR_UNK";
            if ('response' in error)
            {
              if ('exception' in error.response.data)
              {
                const handledExc=error.response.data.exception;
                message="referti.error.ERR_"+ (handledExc.code * -1);

              }

            }
            _this.downloadError(true,message);
          });


    },

    downloadError(show,message)
    {
      if (show)
      {
        this.errorMessage=message;
        this.$refs["referto_error"].show();
      }
      else {

        this.$refs["referto_error"].hide();
        this.errorMessage=null;
      }
    },

    downloadFile(blob, fileName){
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },

    checkSanAmm()
    {
      return true;// this.actReferti.length>0 && this.actReferti[0].ControlloAmministrativoOK && this.actReferti[0].ControlloSanitarioOK;
    },    
  },
};
</script>
